import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrdersFlattenedReport from './orders-flattened';
import ShippedOrdersFlattenedReport from './shipped-orders-flattened';
import InventoryReport from './inventory';
import InventoryV2Report from './inventory-v2';
import InventoryBackOrders from './inventory-backorders';
import InventoryMovements from './inventory-movements';
import InventoryLocations from './inventory-locations';
import InventoryProductLedger from './inventory-product-ledger';
import FulfillmentReport from './fulfillment';
import FulfillmentByOrderReport from './fulfillment-by-order';
import InactiveCustomerReport from './inactive-customers';
import UserAllotments from './user-allotments';

const routes = (props) => (
  <Switch>
    <Route path="/reports/microsites/orders-flattened" component={OrdersFlattenedReport} />
    <Route
      path="/reports/microsites/shipped-orders-flattened"
      component={ShippedOrdersFlattenedReport}
    />

    <Route path="/reports/microsites/inventory" component={InventoryReport} />
    <Route path="/reports/microsites/inventory-v2" component={InventoryV2Report} />
    <Route path="/reports/microsites/user-allotments" component={UserAllotments} />

    <Route path="/reports/microsites/inventory-backorders" component={InventoryBackOrders} />
    <Route path="/reports/microsites/inventory-movements" component={InventoryMovements} />
    <Route path="/reports/microsites/inventory-locations" component={InventoryLocations} />
    <Route path="/reports/microsites/inventory-product-ledger" component={InventoryProductLedger} />
    <Route path="/reports/microsites/fulfillment" component={FulfillmentReport} />
    <Route path="/reports/microsites/fulfillment-by-order" component={FulfillmentByOrderReport} />
    <Route path="/reports/microsites/inactive-customers" component={InactiveCustomerReport} />
  </Switch>
);

export default routes;
